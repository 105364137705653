import { Telemetry } from '@videoblocks/kafka-rest-client';
import { fetchTypeahead as sharedFetchTypeahead } from '@videoblocks/shared-components/dist/mjs/components/Shared/SearchUtils';

import { SearchFilterContentTypes } from '../../Shared/enums';

export function fetchTypeahead(
  contentType: SearchFilterContentTypes,
  query = '',
  typeaheadEnhancementExperiment = false
) {
  const typeAheadTimer = Telemetry.timer('search.frontEnd.typeAhead');
  return sharedFetchTypeahead(
    contentType,
    query,
    typeaheadEnhancementExperiment,
    typeAheadTimer
  );
}

export const searchTypeToRouteMap = {
  footage: 'video',
  'motion-backgrounds': 'motion-graphics',
  templates: 'templates',
};

export function contentTypeToUrlType(
  contentType: SearchFilterContentTypes | string
) {
  let typeUrlPart;
  switch (contentType) {
    case SearchFilterContentTypes.All_videos_content_type:
    case SearchFilterContentTypes.All_audio_content_type:
    case SearchFilterContentTypes.All_images_content_type:
      typeUrlPart = '';
      break;
    case 'motionbackgrounds':
      typeUrlPart = 'motion-backgrounds';
      break;
    case 'sfx':
      typeUrlPart = 'sound-effects';
      break;
    default:
      typeUrlPart = contentType;
  }
  return typeUrlPart;
}

export function truncateString(word, maxLength = 20) {
  if (word.length > maxLength) {
    return word.slice(0, maxLength) + '...';
  }

  return word;
}

export const isEncoded = (string = '') => {
  try {
    // if string is not encoded or cannot be decoded then it will throw an exception
    decodeURIComponent(string);
    return true;
  } catch (e) {
    return false;
  }
};

// This fixes a bug in v5 of react-router where "%" is not encoded properly.
// Upgrading to v6.22.1 should fix this in the future.
// https://github.com/remix-run/react-router/issues/10814
// TODO: can be removed when updated to v6

export const encodeSearchTerm = (searchTerm = '') => {
  return isEncoded(searchTerm) ? searchTerm : encodeURIComponent(searchTerm);
};

export const getDecodedSearchTerm = (searchTerm = '') => {
  return decodeURIComponent(encodeSearchTerm(searchTerm));
};
