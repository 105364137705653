import { builder } from '@builder.io/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Telemetry } from '@videoblocks/kafka-rest-client';
import NavSearch from '@videoblocks/shared-components/dist/mjs/components/Search/NavSearch';
import { searchOrigins } from '@videoblocks/shared-components/dist/mjs/components/Shared/constants';
import { SearchFilterContentTypes } from '@videoblocks/shared-components/dist/mjs/components/Shared/enums';

import { audioSetFiltersChanged } from '../../../Audio/actions/AudioActions';
import {
  selectAuthPermissions,
  selectHasAnySubscription,
} from '../../../auth/AuthSelectors';
import deferToNextFrame from '../../Shared/utils/deferToNextFrame';
import {
  isSearchAppLoaded,
  setLoadingForSearch,
  updateSearchOptionsAndRefresh,
} from '../actions/SearchActions';
import SearchFilterOptions from '../entities/SearchFilterOptions';
import {
  selectIsEnterpriseMember,
  selectSearchFilterOptions,
} from '../selectors/searchSelectors';
import { fetchTypeahead } from '../utils/searchUtils';
import { SelectedSearchFilterOptions } from './MenuContainerInterfaces';

const constructSearchFilterOptions = (
  contentType,
  searchTerm,
  searchOrigin = searchOrigins.SEARCH_BAR
): Partial<SelectedSearchFilterOptions> => {
  const contentClass =
    SearchFilterOptions.getContentClassFromContentType(contentType);
  return {
    contentClass,
    contentType,
    isPagination: false,
    page: 1, // go back to page 1 when any filter is changed
    searchOrigin,
    searchTerm,
    similarTo: null,
    searchSimilarTitle: null,
  };
};

export const handleSubmit = (
  contentType: string,
  searchTerm: string,
  searchOrigin: string,
  dispatch: any,
  selectedSearchFilterOptions: SelectedSearchFilterOptions
) => {
  dispatch(setLoadingForSearch(true));

  // Defer work that might slow down UI updates.
  deferToNextFrame(() => {
    dispatch(audioSetFiltersChanged(true));
  });

  deferToNextFrame(() => {
    builder.init('e7fb3d1e4da14573bd2a1edb7bfee5f1');
    builder.track('search');
  });

  deferToNextFrame(() => {
    const newSelectedSearchFilterOptions = selectedSearchFilterOptions.update(
      constructSearchFilterOptions(contentType, searchTerm, searchOrigin)
    );
    dispatch(updateSearchOptionsAndRefresh(newSelectedSearchFilterOptions));
  });
};

function NavSearchContainer() {
  const dispatch = useDispatch<any>();

  const selectedSearchFilterOptions = useSelector(selectSearchFilterOptions);
  const userHasAnySubscription = useSelector(selectHasAnySubscription);
  const isEnterpriseMember = useSelector(selectIsEnterpriseMember);
  const { pproPluginEnabled } = useSelector(selectAuthPermissions);

  const { contentType = '' } = selectedSearchFilterOptions;

  return (
    <NavSearch
      contentType={contentType as SearchFilterContentTypes}
      onSubmit={(
        contentType: string,
        searchTerm: string,
        searchOrigin: string
      ) =>
        handleSubmit(
          contentType,
          searchTerm,
          searchOrigin,
          dispatch,
          selectedSearchFilterOptions
        )
      }
      userHasAnySubscription={userHasAnySubscription}
      fetchTypeahead={fetchTypeahead}
      isEnterpriseMember={isEnterpriseMember}
      pproPluginEnabled={pproPluginEnabled}
      isSearchAppLoaded={isSearchAppLoaded}
      incrementTelemetry={Telemetry.increment}
      imagesUrl={__ASSETS_COMMON_IMAGES_URL__}
    />
  );
}

export default NavSearchContainer;
